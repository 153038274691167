import React from "react";
import { Header, Footer } from "../MainPage";
import ton from './ton.svg'
import './BridgePage.css'
import exchange from './exchange.svg'
import { Dropdown } from "./components/Dropdown";
import { ExchangeElement } from "./components/ExchangeElement";
import { ExchangeInput } from "./components/ExchangeInput";


export const BridgePage: React.FC = () => {
    return (
        <div className="bridge-page">
            <Header />
                <div className="exchange">
                    <div className="exchange-body">
                    <ExchangeElement title="From" balance={30}>
                            <React.Fragment>
                                <div className="coin-options">
                                    <div className="base-coin">
                                        <img src={ton} alt="" /> 
                                        <span>TON</span>
                                    </div>
                                    <div className="buttons">
                                        <button type="button">25%</button>
                                        <button type="button">50%</button>
                                        <button type="button">75%</button>
                                        <button type="button">100%</button>
                                    </div>
                                </div>
                                <ExchangeInput defaultCoin="MOON" />
                            </React.Fragment>
                        </ExchangeElement>
                        <div className="exchange-icon">
                            <img src={exchange} alt="" />
                        </div>
                        <ExchangeElement title="To">
                            <React.Fragment>
                                <div className="dropdown-area">
                                    <Dropdown defaultCoin="ETH" />
                                </div>
                                <ExchangeInput defaultCoin="MOON" />
                            </React.Fragment>
                        </ExchangeElement>
                        <ExchangeElement title="Destination address">
                            <React.Fragment>
                                <div className="exchange-input">
                                    <input type="text" placeholder="1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71" />
                                </div>
                                <div className="bridge-button">
                                    <button type="button">Bridge</button>
                                </div> 
                            </React.Fragment>
                        </ExchangeElement>
                    </div>
                </div>
            <Footer />
        </div>
    )
}