import React, { useState } from "react";
import moon from '../moon-icon.svg';
import pepe from '../pepe-icon.svg';
import doge from '../doge-icon.svg';
import arrow from '../arrow-down.svg'
import eth from '../eth-icon.svg'


const icons = {
    MOON: moon,
    PEPE: pepe,
    DOGE: doge,
    ETH: eth,
} as const;

export type IconName = keyof typeof icons;

interface DropdownProps {
    defaultCoin: IconName
}

export const Dropdown: React.FC<DropdownProps> = ({ defaultCoin }) => {

    const [current, setCurrent] = useState<IconName>(defaultCoin);
    const [toggle, setToggle] = useState<boolean>(false);
    
    return (
        <div className="dropdown">
            <div className="currnet-coin" onClick={() => {
                    setToggle(!toggle)
            }}>
                <img src={icons[current]} alt="current-coin"/> 
                {current} 
                <img src={arrow} alt=""/>
            </div>
            <div className={toggle ? 'dropdown-options' : 'dropdown-options closed'}>
                {(Object.keys(icons) as IconName[]).map((name) => (
                    <div key={name} className="dropdown-element" onClick={() => setCurrent(name)}>
                        <img src={icons[name]} alt={name} /> <span>{name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
