import React, { ReactNode} from "react";

interface ExchangeElementProps {
    title: string,
    balance?: number,
    children: ReactNode
}

export const ExchangeElement: React.FC<ExchangeElementProps> = ({ title, balance, children }) => {
    return (
        <div className="exchange-element">
            <div className="exchange-element-body">
                <div className="exchange-element-titles">
                    <span>{title}</span>
                    {balance && <span>Balance: {balance} MOON</span>}
                </div>
                {children}
            </div>
        </div>
    )
}