import React from "react";
import { IconName } from "./Dropdown";
import { Dropdown } from "./Dropdown";


interface ExchangeInputProps {
    defaultCoin: IconName
}

export const ExchangeInput: React.FC<ExchangeInputProps> = ({ defaultCoin }) => (
    <div className="exchange-input">
        <div className="dropdown-input-area">
            <Dropdown defaultCoin={defaultCoin} />
        </div>
    <input type="number" placeholder="0.0" />
    </div>
)
